@font-face {
  font-family: 'HouschkaRounded';
  src: url('/font/Houschka Rounded Alt Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'HouschkaRounded';
  src: url('/font/Houschka Rounded Alt Medium.ttf');
}

* {
  font-family: 'HouschkaRounded', 'Segoe UI', 'Segoe UI Web (West European)',
    'Segoe UI', '-apple-system', 'BlinkMacSystemFont', 'Roboto',
    'Helvetica Neue', 'sans-serif';
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}
.react-multi-carousel-dot button {
  background: #a1a1a1;
  border: none;
}
.react-multi-carousel-dot--active button {
  background: #0477ff;
}

.grecaptcha-badge {
  z-index: 100;
}
